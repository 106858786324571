import React, { useState, Fragment } from "react";
import $ from "styled-components";
import InputValue from "../comps/input-value";
import OutputValue from "../comps/output-value";
import FrontWeightDistribution from "../comps/front-weight-distribution";
import StickyFrontWeightDistribution from "../comps/sticky-front-weight-distribution";

interface IState {
  frontWeightDis: string;
  reboundMin: string;
  reboundMax: string;
  springMin: string;
  springMax: string;
  antiRollMin: string;
  antiRollMax: string;
}

const Calculator: React.SFC = () => {
  const [values, setValues] = useState<IState>({
    frontWeightDis: "50",
    reboundMin: "3",
    reboundMax: "20",
    springMin: "59.7",
    springMax: "298.7",
    antiRollMin: "1",
    antiRollMax: "65"
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <Fragment>
      <Container>
        <FrontWeightDistribution
          id="frontWeightDis"
          value={values.frontWeightDis}
          onChange={handleInputChange}
        />

        <Header>
          <Heading>AntiRoll Bars</Heading>
          <Column>
            <InputValue
              id="antiRollMin"
              label="Soft"
              labelAlign="left"
              value={values.antiRollMin}
              onChange={handleInputChange}
              min_or_max="min"
            />
            <InputValue
              id="antiRollMax"
              label="Stiff"
              labelAlign="right"
              value={values.antiRollMax}
              onChange={handleInputChange}
              min_or_max="max"
            />
          </Column>
        </Header>
        <OutputValue
          label="Front"
          frontWeightDis={values.frontWeightDis}
          min={values.antiRollMin}
          max={values.antiRollMax}
        />
        <OutputValue
          label="Rear"
          frontWeightDis={values.frontWeightDis}
          min={values.antiRollMin}
          max={values.antiRollMax}
        />

        <Header>
          <Heading>Springs</Heading>
          <Column>
            <InputValue
              id="springMin"
              label="Soft"
              labelAlign="left"
              value={values.springMin}
              onChange={handleInputChange}
              min_or_max="min"
            />
            <InputValue
              id="springMax"
              label="Stiff"
              labelAlign="right"
              value={values.springMax}
              onChange={handleInputChange}
              min_or_max="max"
            />
          </Column>
        </Header>
        <OutputValue
          label="Front"
          frontWeightDis={values.frontWeightDis}
          min={values.springMin}
          max={values.springMax}
        />
        <OutputValue
          label="Rear"
          frontWeightDis={values.frontWeightDis}
          min={values.springMin}
          max={values.springMax}
        />

        <Header>
          <Heading>Rebound Stiffness</Heading>
          <Column>
            <InputValue
              id="reboundMin"
              label="Soft"
              labelAlign="left"
              value={values.reboundMin}
              onChange={handleInputChange}
              min_or_max="min"
            />
            <InputValue
              id="reboundMax"
              label="Stiff"
              labelAlign="right"
              value={values.reboundMax}
              onChange={handleInputChange}
              min_or_max="max"
            />
          </Column>
        </Header>
        <OutputValue
          label="Front"
          frontWeightDis={values.frontWeightDis}
          min={values.reboundMin}
          max={values.reboundMax}
        />
        <OutputValue
          label="Rear"
          frontWeightDis={values.frontWeightDis}
          min={values.reboundMin}
          max={values.reboundMax}
        />
        <Header>
          <Heading>Bump Stiffness</Heading>
        </Header>
        <OutputValue
          label="Front"
          frontWeightDis={values.frontWeightDis}
          min={values.reboundMin}
          max={values.reboundMax}
          multiplier={0.6}
        />
        <OutputValue
          label="Rear"
          frontWeightDis={values.frontWeightDis}
          min={values.reboundMin}
          max={values.reboundMax}
          multiplier={0.6}
        />
      </Container>
      <StickyFrontWeightDistribution
        id="frontWeightDis"
        value={values.frontWeightDis}
        onChange={handleInputChange}
      />
    </Fragment>
  );
};

const Container = $.div`
  margin: 0 auto;
  max-width: 768px;
`;

const Header = $.div`
  margin-top: 64px;
  @media (min-width: 500px ) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

const Heading = $.h2`
  font-size: 1.15rem;
  color: #3D4852;
  line-height: 1;
  text-transform: uppercase;
  flex: 1;
  margin: 0 0 12px;
  @media (min-width: 500px ) {
    margin: 0;
  }
`;

const Column = $.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export default Calculator;
