import * as React from "react";
import $ from "styled-components";

interface IProps {
  id: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FrontWeightDistribution: React.SFC<IProps> = ({
  id,
  value,
  onChange
}) => {
  return (
    <Container>
      <Label htmlFor={id}>Front Weight Distribution</Label>
      <InputContainer>
        <Input
          id={id}
          name={id}
          onChange={onChange}
          value={value}
          type="number"
          pattern="[0-9]*"
          inputMode="decimal"
          autoComplete="off"
          autoCapitalize="off"
          autoFocus
          min="0"
          max="100"
        />
        <InputUnit>%</InputUnit>
      </InputContainer>
    </Container>
  );
};

const Container = $.div`
  @media (min-width: 500px) {
    display: flex;
    align-items: center;
  }
`;

const Label = $.label`
  display: block;
  flex: 1;
  font-size: 1.15rem;
  font-weight: bold;
  color: #3D4852;
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 8px;
  @media (min-width: 500px ) {
    margin: 0;
  }
`;

const InputContainer = $.div`
  position: relative;
  width: 96px;
`;

const InputUnit = $.div`
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #B8C2CC;
  text-transform: uppercase;
`;

const Input = $.input`
  width: 96px;
  font-size: 1rem;
  font-weight: 700;
  text-align: right;
  padding: 12px;
  padding-right: 32px;
  background-color: #f3f9ff;
  border: 0;
  border-radius: 12px;
  box-shadow: none;
  margin-right: 4px;
  transition: background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  box-shadow: 0 0 0 0 rgba(255, 8, 88, 0.8);
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(255, 8, 88, 0.8);
    background-color: white;
  }
`;

export default FrontWeightDistribution;
