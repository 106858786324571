import * as React from "react";
import $ from "styled-components";

export interface IProps {
  label: "Front" | "Rear";
  frontWeightDis: string;
  min: string;
  max: string;
  multiplier?: number;
}

const OutputValue: React.FunctionComponent<IProps> = ({
  label,
  frontWeightDis,
  min,
  max,
  multiplier
}) => {
  const results = findOutputValue(frontWeightDis, min, max);
  let result = label === "Front" ? results.front : results.rear;

  if (multiplier) {
    result = (Number(result) * multiplier).toFixed(2);
  }

  return (
    <Container>
      <Label>{label}</Label>
      <Line>
        <LineFill
          style={{
            width: `${getMarkerPosition(max, result)}%`
          }}
        />
      </Line>
      <Value>{result}</Value>
    </Container>
  );
};

const Container = $.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 1em;
  padding: 16px 24px 16px 16px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 8px 4px 16px -4px rgba(0,0,0,0.2);
`;

const Label = $.h3`
  width: 68px;
  margin: 0;
  font-size: 1rem;
  color: #606F7B;
  text-transform: uppercase;
  letter-spacing: -0.22px;
  @media (min-width: 500px) {
    flex: 1;
  }
`;

const Line = $.div`
  position: relative;
  flex:1;
  height: 4px;
  border-radius: 6px;
  background-color: #DAE1E7;
  overflow: hidden;
  @media (min-width: 500px) {
    margin-left: 72px;
  }
`;

const LineFill = $.div`
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 12px;
  background-color: rgba(255, 8, 88, 1);
`;

const Value = $.div`
  font-size: 1.125rem;
  font-weight: bold;
  text-align: right;
  margin-left: 16px;
`;

function getMarkerPosition(max: string, value: string): string {
  return ((Number(value) / Number(max)) * 100).toString();
}

function findOutputValue(
  frontWeightDis: string,
  min: string,
  max: string
): { front: string; rear: string } {
  // Convert props to numbers
  const frontWeightDisConverted = Number(frontWeightDis);
  const minConverted = Number(min);
  const maxConverted = Number(max);

  // takes a whole number and converts to a float
  const frontWeightDisFloat = frontWeightDisConverted / 100;
  // takes a whole number finds the rear weight
  // distribution and converts to a float
  const rearWeightDisFloat = (100 - frontWeightDisConverted) / 100;

  const frontResult =
    (maxConverted - minConverted) * frontWeightDisFloat + minConverted;

  const rearResult =
    (maxConverted - minConverted) * rearWeightDisFloat + minConverted;

  return {
    front: frontResult.toFixed(2),
    rear: rearResult.toFixed(2)
  };
}

export default OutputValue;
