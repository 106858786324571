import * as React from "react";
import $ from "styled-components";

export interface IProps {
  id: string;
  label: string;
  labelAlign: "left" | "right";
  value: string;
  min_or_max: "min" | "max";
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputValue: React.SFC<IProps> = ({
  id,
  label,
  labelAlign,
  value,
  min_or_max,
  onChange
}) => {
  return (
    <Container>
      <Label style={{ textAlign: labelAlign }} htmlFor={id}>
        {label}
      </Label>
      <InputContainer>
        <Input
          id={id}
          name={id}
          onChange={onChange}
          value={value}
          type="number"
          pattern="[0-9]*"
          inputMode="decimal"
          autoComplete="off"
          autoCapitalize="off"
        />
        <InputUnit>{min_or_max}</InputUnit>
      </InputContainer>
    </Container>
  );
};

const Container = $.div`
`;

const Label = $.label`
  display: block;
  font-size: 0.875rem;
  font-weight: bold;
  color: #8795A1;
  text-transform: uppercase;
  margin-right: 10px;
`;

const InputContainer = $.div`
  position: relative;
  margin-top: 4px;
`;

const InputUnit = $.div`
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #B8C2CC;
  text-transform: uppercase;
`;

const Input = $.input`
  width: 96px;
  font-size: 1rem;
  font-weight: 700;
  text-align: right;
  padding: 12px;
  padding-right: 40px;
  background-color: #f3f9ff;
  border: 0;
  border-radius: 12px;
  box-shadow: none;
  margin-right: 4px;
  transition: background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  box-shadow: 0 0 0 0 rgba(255, 8, 88, 0.8);
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(255, 8, 88, 0.8);
    background-color: white;
  }
`;

export default InputValue;
