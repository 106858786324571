import React, { useState, useEffect } from "react";
import $ from "styled-components";
import { useScrollYPosition } from "react-use-scroll-position";

interface IProps {
  id: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const StickyFrontWeightDistribution: React.SFC<IProps> = ({
  id,
  value,
  onChange
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const scrollY = useScrollYPosition();

  useEffect(() => {
    return () => {
      setIsVisible(scrollY > 172);
    };
  });

  return (
    <Sticky
      style={{
        opacity: isVisible ? 1 : 0,
        transform: `translateY(${isVisible ? "0" : "-56px"})`
      }}
    >
      <Container>
        <Label htmlFor={id}>FWD</Label>
        <InputContainer>
          <Input
            id={id}
            name={id}
            onChange={onChange}
            value={value}
            tabIndex={-1}
            type="number"
            pattern="[0-9]*"
            inputMode="decimal"
            autoComplete="off"
            autoCapitalize="off"
            min="0"
            max="100"
          />
          <InputUnit>%</InputUnit>
        </InputContainer>
      </Container>
    </Sticky>
  );
};

const Sticky = $.div`
  transition: transform 0.15s ease-out, opacity 0.15s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 56px;
  padding: 12px 16px;
  background-color: white;
  box-shadow: 0 2px 20px -10px rgba(0,0,0,0.5);
`;

const Container = $.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
`;

const Label = $.label`
  display: block;
  flex: 1;
  font-size: 1.15rem;
  font-weight: bold;
  color: #3D4852;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
`;

const InputContainer = $.div`
  position: relative;
  width: 96px;
`;

const InputUnit = $.div`
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #B8C2CC;
  text-transform: uppercase;
`;

const Input = $.input`
  width: 96px;
  font-size: 1rem;
  font-weight: 700;
  text-align: right;
  padding: 8px 12px;
  padding-right: 32px;
  background-color: #f3f9ff;
  border: 0;
  border-radius: 12px;
  box-shadow: none;
  margin-right: 4px;
  transition: background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  box-shadow: 0 0 0 0 rgba(255, 8, 88, 0.8);
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(255, 8, 88, 0.8);
    background-color: white;
  }
`;

export default StickyFrontWeightDistribution;
