import React from "react";
import Calculator from "./scenes/calculator";
import $ from "styled-components";

function App() {
  return (
    <Content>
      <Heading>
        <span>Forza</span>
        Simple Tune
      </Heading>
      <Calculator />
    </Content>
  );
}

const Content = $.div`
background: rgb(232,247,255);
background: linear-gradient(180deg, rgba(232,247,255,1) 0%, rgba(239,229,255,1) 100%);
  padding: 1.5em;
  padding-bottom: 10em;
`;

const Heading = $.h1`
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 3.5px;
  margin-bottom: 56px;
  text-align: center;
  & > span {
    display: inline-block;
    border-bottom: 4px solid black;
    font-size: 2rem;
    letter-spacing: -2.5px;
    font-style: italic;
    margin-right: 16px;
  }
`;

export default App;
